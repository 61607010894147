import slugify from '@sindresorhus/slugify';

import { Product } from '~/customTypes';

export const uiRoutes = {
  root: () => '/',
  assetDetailsCrypto: (asset: string) => `/assets/crypto/${slugify(asset)}`,
  assetDetailsSecurities: (asset: string) => `/assets/securities/${slugify(asset, { preserveCharacters: ['.'] })}`,
  learn: () => '/learn',
  investments: () => '/investments',
  sectionDetails: (section: string) => `/learn/${slugify(section)}`,
  transactionHistory: (args: { securitiesTicker?: string; token?: string } = {}) => {
    let queryParams = '';
    const { securitiesTicker, token } = args;
    switch (true) {
      case !!securitiesTicker:
        queryParams = `?securitiesTicker=${securitiesTicker}`;
        break;
      case !!token:
        queryParams = `?token=${token}`;
        break;
    }

    return `/transaction-history${queryParams}`;
  },
  documents: () => '/documents',
};

// trim trailing slash from API URL if necessary
const apiUrl = process.env.NEXT_PUBLIC_API_URL ? process.env.NEXT_PUBLIC_API_URL.replace(/\/$/, '') : '';
const baseUrl = `${apiUrl}/v2`;

export const apiRoutes = {
  // CWS API routes
  auth: () => `${baseUrl}/dbp/auth`,
  bankAccounts: () => `${baseUrl}/user/bank-accounts`,
  price: () => `${baseUrl}/token/current/price`,
  priceHistory: () => `${baseUrl}/token/historical/price`,
  user: () => `${baseUrl}/user/user`,
  searchOrders: () => `${baseUrl}/user/orders/search`,
  transactions: () => `${baseUrl}/user/transactions`,
  fiInfo: () => `${baseUrl}/user/fi-info`,
  kyc: (product?: Product) => `${baseUrl}/user/kyc${product ? `?product=${product.toUpperCase()}` : ''}`,
  kycFields: (product?: Product) => `${baseUrl}/user/kyc/fields${product ? `?product=${product.toUpperCase()}` : ''}`,
  statements: () => `${baseUrl}/user/statements`,
  statement: (id: string) => `${baseUrl}/user/statements/${id}`,
  fees: (price: number) => `${baseUrl}/user/fees?price=${price}&currency=USD`,
  buyCrypto: () => `${baseUrl}/user/buy`,
  sellCrypto: () => `${baseUrl}/user/sell`,
  buySecurity: () => `${baseUrl}/user/securities/buy`,
  sellSecurity: () => `${baseUrl}/user/securities/sell`,
  terms: (product?: Product) => `${baseUrl}/user/terms${product ? `?product=${product.toUpperCase()}` : ''}`,
  telemetryEvent: () => `${baseUrl}/user/event`,
  systemStatus: () => `${baseUrl}/public/status`,

  //Next.js internal API routes
  redis: {
    getSecurity: ({ ticker }: { ticker: string }) => `/api/securities/${ticker}/get`, // get one security
    getSecurityFields: ({ ticker }: { ticker: string }) => `/api/securities/${ticker}/fields`, // get specific fields of a security
    getSecurities: ({ tickers }: { tickers: string }) => `/api/securities/get?tickers=${tickers}`, // get one or many securities
    searchSecurities: ({ query }: { query: string }) => `/api/securities/search?query=${query}`, // fuzzy search securities
  },
  intrinio: {
    getSecurity: ({ ticker }: { ticker: string }) => `/api/securities/${ticker}/details`, // get general security details
    getSecurityDailyPrice: ({ ticker }: { ticker: string }) => `/api/securities/${ticker}/daily-price`, // get security daily price data points
    getSecurityMetrics: ({ ticker }: { ticker: string }) => `/api/securities/${ticker}/metrics`, // get security daily metrics
  },
};
